import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { FaSpinner } from 'react-icons/fa'

import Button from './button'
import localStorage from '../src/local-storage'

import { HelpScout } from '../contexts/help-scout'
import { UserContext } from '../contexts/user-context'

const FooterBar = styled.div`
  background-color: #484848;
  color: #efefef;
  margin-top: 75px;
  font-size: 13px;
  padding: 10px 20px;
  a {
    color: #efefef;
  }
  ${props => props.theme.media.desktop`
    height: 50px;
    font-size: 18px;
  `}
`

const FooterInside = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 970px;
  display: flex;
  justify-content: space-between;
  > :nth-child(2) {
    display: none;
  }
  > :nth-child(3) {
    display: none;
  }
  > :nth-child(4) {
    display: none;
  }
  ${props => props.theme.media.desktop`
    > :nth-child(2) {
      display: block;
    }
    > :nth-child(3) {
      display: block;
    }
    > :nth-child(4) {
      display: block;
    }
    
  `}
`

const FooterMobile = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: flex-start;
  > div {
    width: 75px;
  }
  ${props => props.theme.media.desktop`
    display: none;
  `}
`

const CookieBanner = styled.div`
  position: fixed;
  bottom: 50px;
  left: 10px;
  right: 10px;
  background-color: #484848;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  padding: 10px;
  text-align: center;
  z-index: 2000;
  ${props => props.theme.media.desktop`
    bottom: 75px;
    width: 325px;
    left: 10px;
    right: auto;
  `}
  
`

const Help = styled.div`
  position: fixed;
  z-index: 49;
  right: 15px;
  bottom: 10px;
  min-width: 65px;
  height: 35px;
  border-radius: 200px;
  color: black;
  background-color: rgb(239, 239, 239);
  text-align: center;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 7px;
  transition: background-color 200ms linear 0s, transform 200ms linear 0s;
  :hover {
    background-color: #ccc;
  }
  ${props => props.theme.media.desktop`
    right: 40px;
    bottom: 40px;
  `}
`

const Footer = () => {
  const helpScout = useContext(HelpScout)
  const userHook = useContext(UserContext)
  const user = userHook.user
  
  const [showFooter, setShowFooter] = useState(true)
  const [showCookieConsent, setShowCookieConsent] = useState(false)
  const [cookieConsent, setCookieConsent] = useState()
  useEffect(()  => {
    const cc = localStorage.getCookieConsent()
    if (typeof cc === 'undefined' && (!user.id || user.cookies === null || user.cookies === 'null')) setShowCookieConsent(true)
  }, [user.id])

  useEffect(() => {
    if (window.isNativeApp) setShowFooter(false)
  }, [])

  const action = async (consent) => {
    setShowCookieConsent(false)
    if (user.id) {
      try {
        const { ['challengesJoined']: remove1, ['groupsJoined']: remove2, ['organizations']: remove3, ['integrations']: remove4, ...trimmed } = user
        trimmed.cookies = consent
        const putConfig = {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': user.token
          },
          body: JSON.stringify(trimmed)
        }
        const res = await fetch(`${process.env.API}/users/${user.id}`, putConfig)
        if (!res.ok) {
          const errMsg = await res.text()
          console.error(errMsg)
        }
      
        userHook.mutate()
      } catch (err) {
        console.error(err)
      }
    } else {
      localStorage.setCookieConsent(consent)
    }
  }
  
  return showFooter ? <FooterBar>
    {showCookieConsent && <CookieBanner>
      By using Challenge Hound, you agree to our <Link href='/cookies' style={{ color: 'orange' }}>Cookie Policy</Link>. Some features will not work if you reject cookies.<br/>
      <Button buttonType='secondary' style={{ marginTop: '10px', marginRight: '50px' }} onClick={() => action(false)}>Reject</Button>
      <Button style={{ marginTop: '10px' }} onClick={() => action(true)}>Accept</Button>
    </CookieBanner>}
    {!helpScout.helpScoutLoaded && <Help onClick={() => {
      helpScout.loadBeacon(true)
    }}>{helpScout.helpScoutLoading ? <FaSpinner style={{ color: '#999' }} /> : 'Help'}</Help>}
    <FooterInside>
      <div style={{ width: 'auto' }}>&copy; Challenge Hound LLC</div>
      <div style={{ width: 'auto' }}><Link href='/privacy'>Privacy</Link></div>
      <div style={{ width: 'auto' }}><Link href='/cookies'>Cookies</Link></div>
      <div style={{ width: 'auto' }}><Link href='/terms'>Terms</Link></div>
    </FooterInside>
    <FooterMobile>
      <div><Link href='/privacy'>Privacy</Link></div>
      <div><Link href='/cookies'>Cookies</Link></div>
      <div><Link href='/terms'>Terms</Link></div>
    </FooterMobile>
  </FooterBar> : <div style={{ height: '30px' }}></div>
}

export default Footer
