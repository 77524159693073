import { css } from 'styled-components'

const sizes = {
  desktop: 768, // normally 992,
  mobile: 576
}

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export const theme = {
  primary: '#FF851B',
  primaryText: 'white',
  primaryBtnHover: '#9e4b04',
  secondaryBtnHover: '#6e3402',
  secondary: '#9e4b04',
  secondaryTest: '',
  bodyBgColor: '#FAFAFA',
  successBg: '#2C6700',
  success: '#ffff',
  warningBg: '#FFCC00',
  warning: '#333',
  media
}

