import styled from 'styled-components'

export const Button = styled.button`
  background-color: ${props => {
    if (props.buttonType === 'plain') return '#FAFAFA'
    if (props.buttonType === 'delete') return '#333'
    if (props.buttonType === 'confirm') return props.theme.successBg
    if (props.buttonType === 'secondary') return props.theme.secondary
    return props.theme.primary
  }};
  color: ${props => {
    if (props.buttonType === 'plain') return '#333'
    if (props.buttonType === 'delete') return '#fff'
    if (props.buttonType === 'confirm') return props.theme.success
    if (props.buttonType === 'secondary') return '#efefef'
    return props.theme.primaryText
  }};
  font-size: ${props => {
    if (props.size === 'lg') return '1.20em'
    return '.90em'
  }};
  padding: 0.45em .7em;
  border: 1px solid ${props => {
    if (props.buttonType === 'plain') return '#999'
    if (props.buttonType === 'delete') return '#333'
    if (props.buttonType === 'confirm') return props.theme.successBg
    if (props.buttonType === 'secondary') return props.theme.secondary
    return props.theme.primary
  }};
  border-radius: 8px;
  cursor: pointer;
  outline: 0;
  white-space: nowrap;
  float: ${props => props.float || ''};
  :hover {
    background-color: ${props => {
    if (props.buttonType === 'plain') return '#efefef'
    if (props.buttonType === 'delete') return '#666'
    if (props.buttonType === 'confirm') return '#469e05'
    if (props.buttonType === 'secondary') return props.theme.secondaryBtnHover
    return props.theme.primaryBtnHover
  }};  
    border: 1px solid ${props => {
    if (props.buttonType === 'plain') return '#ccc'
    if (props.buttonType === 'delete') return '#666'
    if (props.buttonType === 'confirm') return props.theme.successBg
    if (props.buttonType === 'secondary') return props.theme.secondaryBtnHover
    return props.theme.primaryBtnHover
  }};
  }
  :disabled {
    opacity: .7;
    cursor: not-allowed;
    :hover {
      background-color: ${props => props.theme.primary};
    }
  }
`

const ButtonComponent = (props) => <Button
  type={props.type}
  buttonType={props.buttonType}
  disabled={props.disabled}
  onClick={props.onClick}
  style={props.style}
  size={props.size}
  float={props.float}>{props.children}</Button>

export default ButtonComponent