import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import Image from 'next/image'
import { FaTwitter, FaInstagram, FaFacebook } from 'react-icons/fa'
import { Badge, Button } from '@nextui-org/react'

import { formatNumber } from '../src/formatter'

import useHasMounted from '../hooks/use-has-mounted'
import { UserContext } from '../contexts/user-context'

const HeaderBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5px 10px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  ${props => props.theme.media.desktop`
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    max-width: 970px;
    margin-top: 5px;
  `}
`

const FirstColumn = styled.div`
  display: flex:
  flex-direction: column;
  flex: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  min-width: 250px;
  ${props => props.theme.media.desktop`
    max-width: 285px;
  `}
`

const SecondColumn = styled.div`
  display: flex:
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`

const SocialColumn = styled.div`
  display: none;
  > a {
    color: #B8B8B8;
    :hover {
      color: ${props => props.theme.primary}
    }
  }
  ${props => props.theme.media.desktop`
    display: flex;
    flex: 1;
    align-items: center;
    color: #B8B8B8;
    font-size: 22px;
    justify-content: space-between;
    max-width: 100px;
  `}
`

const SocialMobile = styled.div`
  position: absolute;
  display: flex;
  width: 100px;
  height: 30px;
  top: 45px;
  left: 100px;
  align-items: center;
  > a {
    color: #B8B8B8;
    :hover {
      color: ${props => props.theme.primary}
    }
  }
  font-size: 16px;
  justify-content: space-between;
  max-width: 75px;
  
  ${props => props.theme.media.desktop`
    display: none;
  `}
`



const Logo = styled.div`
  display: flex:
  align-items: center;
  margin-right: 5px;
  margin-top: 5px;
  > a > div > div > img {
    width: 40px;
    height: 40px;
  }
  ${props => props.theme.media.desktop`
    margin-right: 10px;
    margin-top: 0px;
    > a > div > div > img {
      width: 50px;
      height: 50px;
      margin-top: 0px;
    }
  `}
`

const LogoText = styled.div`
  font-family: 'Righteous', sans-serif;
  font-weight: 700;
  font-size: 18px;
  margin-top: 1px;
  flex-grow: 2;
  font-display: swap;
  a {
    color: #B8B8B8;
    :hover {
      color: ${props => props.theme.primary}
    }
  }
  ${props => props.theme.media.desktop`
    margin-top: -2px;
  `}
`

const Nav = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  ${props => props.theme.media.desktop`
    float: right;
    width: ${props => props.width || '500px'};
    margin-top: 15px;
  `}
`

const NavLink = styled.div`
  flex-grow: 1,
  text-align: center;
  > a {
    color: ${props => (props.angry ? '#ff0000;' : (props.active ? props.theme.primaryBtnHover : '#084c8d'))};
    cursor: pointer;
    ${({ active }) => active && `
      font-weight: bolder;
    `}
    :hover {
      color: ${props => props.theme.primary};
    }
  }
  > svg {
    color: red;
    margin-top: -14px;
    margin-left: 3px;
  }
  &:nth-child(5) {
    display: none;
  }
  &:nth-child(6) {
    display: none;
  }
  ${props => props.theme.media.desktop`
    margin-left: 0px;
    margin-left: 15px;
    > svg {
      right: 0;
      margin-top: 0px;
    }
    &:nth-child(5) {
      display: block;
    }
    &:nth-child(6) {
      display: block;
    }
  `}
`

const MobileTopHeader = styled.div`
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
  > a {
    color: ${props => props.active ? props.theme.secondary : props.theme.primary};
  }
  > svg {
    color: red;
    font-size: 16px;
    margin-left: 5px;
  }
  ${props => props.theme.media.desktop`
    display: none;
  `}
`

function Header ({ section, subsection, main }) {
  const [showHeader, setShowHeader] = useState(true)
  const hasMounted = useHasMounted()
  
  const userHook = useContext(UserContext)
  const user = userHook.user

  const hasOrg = Boolean(user.organization && user.organization.balance !== null)
  const lowBalance = 8

  useEffect(() => {
    if (window.isNativeApp) setShowHeader(false)
  }, [])

  const navActiveClass = 'text-[#9e4b04] font-bold'

  return showHeader && <HeaderBar>
    <SocialMobile>
      <a href='https://twitter.com/challengehound'><FaTwitter /></a>
      <a href='https://instagram.com/challengehound'><FaInstagram /></a>
      <a href='https://facebook.com/challengehound'><FaFacebook /></a>
    </SocialMobile>
    <FirstColumn>
      <Logo>
        <Link href='/'>
          <Image src='/favicon.png' alt='Challenge Hound' height={50} width={50} />
        </Link>
      </Logo>
      <LogoText>
        <Link href='/'>
          Challenge Hound
        </Link>
      </LogoText>
      {hasMounted && <>
        {(userHook.loggedIn && hasOrg && section !== 'public') && <MobileTopHeader active={section === 'credits'}>
          <Link href='/credits'>
            <Badge content="low" size='sm' color="danger" className='mr-2 mt-[-7px]' isInvisible={user.organization.balance > lowBalance}>
              <div className='border-1 rounded-md border-gray-300 py-1 px-2 bg-white text-primary hover:text-secondary mt-[-7px]'>
                {formatNumber(user.organization.balance, 1)} Credits
              </div>
            </Badge>
          </Link>
        </MobileTopHeader>}
        {(userHook.loggedIn && section === 'public') && <MobileTopHeader>
          <Link href='/challenges'>My Challenges</Link>
        </MobileTopHeader>}
        {(!userHook.loggedIn && section === 'public' && subsection !== 'signin') && <MobileTopHeader >
          <Link href={'/signup'} as={'/signup'}>
            <Button className='mt-[-7px]' color='primary'>Sign In</Button>
          </Link>
        </MobileTopHeader>}
      </>}
    </FirstColumn>
    <SocialColumn>
      <a href='https://twitter.com/challengehound'><FaTwitter /></a>
      <a href='https://instagram.com/challengehound'><FaInstagram /></a>
      <a href='https://facebook.com/challengehound'><FaFacebook /></a>
    </SocialColumn>
    <SecondColumn>
      {section === 'public' &&
        <Nav width={subsection === 'login' ? '325px': ''}>
          <NavLink active={subsection === 'product'}>
            {subsection === 'home' && <a onClick={(e) => {
              const el = document.querySelector("#features")
              el.scrollIntoView({ behavior: 'smooth' })
            }} style={{cursor: 'pointer'}}>Product</a>}
            {subsection !== 'home' && <Link href='/product' scroll={false}>Product</Link>}
          </NavLink>
          <NavLink active={subsection === 'pricing'}><Link href='/pricing'>Pricing</Link></NavLink>
          <NavLink active={subsection === 'about'}><Link href='/about'>About</Link></NavLink>
          <NavLink active={subsection === 'houndquarters'}><Link href={`/houndquarters`} as={`/houndquarters`}>Houndquarters</Link></NavLink>
          {hasMounted && <>
            {(!userHook.loggedIn && subsection !== 'signin') &&
              <NavLink>
                <Link href={'/signin'} as={'/signin'}>
                  <Button className='mt-[-7px]' color='primary'>Sign In</Button>
                </Link>
              </NavLink>
            }
            {(userHook.loggedIn && subsection !== 'signin' && subsection !== 'signin') &&
              <NavLink>
                <Link href={'/challenges'} as={'/challenges'}>
                  <Button size='sm' color='primary' className='mt-[-7px]'>My Challenges</Button>
                </Link>
              </NavLink>
            }
          </>}
        </Nav>
      }
      {hasMounted && <>
        {(section !== 'public' && userHook.loggedIn) && 
          <Nav>
            <NavLink active={section === 'challenges'}>
              {section === 'challenges' && main ? <span className={navActiveClass}>Challenges</span> : <Link href='/challenges'>Challenges</Link>}
            </NavLink>
            <NavLink active={section === 'activities'}>
              {section === 'activities' && main ? <span className={navActiveClass}>Activities</span> : <Link href='/activities'>Activities</Link>}
            </NavLink>
            <NavLink active={section === 'apps'}>
              {section === 'apps' && main ? <span className={navActiveClass}>Apps</span> : <Link href='/apps'>Apps</Link>}
            </NavLink>
            <NavLink active={section === 'account'} angry={userHook.isAnonymous(user)}>
              <Badge content="Update!" size='sm' color="danger" className='mt-[20px] ml-1' isInvisible={!userHook.isAnonymous(user)} placement="bottom-center">
                {section === 'account' && main ? <span className={navActiveClass}>Account</span> : <Link href='/account'>Account</Link>}
              </Badge>
            </NavLink>
            {hasOrg && <NavLink active={section === 'credits'}>
              <Link href='/credits'>
                <Badge content="low" size='sm' color="danger" className='mr-2 mt-[-6px]' isInvisible={user.organization.balance > lowBalance}>
                  <div className='border-1 rounded-md border-gray-300 py-1 px-2 bg-white text-primary hover:text-secondary mt-[-7px]'>
                    {formatNumber(user.organization.balance, 1)} Credits
                  </div>
                </Badge>
              </Link>
            </NavLink>}
          </Nav>
        }
      </>}
    </SecondColumn>
  </HeaderBar>
}

export default Header
